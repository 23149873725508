import React, { Component } from 'react';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./pages/login/Login'),
  loading
});

const createHistory = require("history").createBrowserHistory;

class App extends Component {
  constructor(props) {
    super(props);

    const history = createHistory();

    this.state = {
      history: history
    }
  }

  render() {
    return (
      <Router history={this.state.history}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </Router>
    );
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    render={
      props =>
        localStorage.getItem('token')
        ? <Component {...props} /> 
        : <Redirect to={{
            pathname: "/login",
            state: { from: props.location }
          }} />
    }
    {...rest}
  />
)

export default App;